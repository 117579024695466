<template lang="pug">
div
  router-view(
    :openEmployeeModal="openEmployeeModal"
    :openImportDialog="openImportDialog"
  )

  v-dialog(
    v-if="$can('UPDATE_EMPLOYEES')"
    v-model="createDialog"
    max-width="700px"
  )
    Form(
      v-if="createDialog"
      :loading="$root.$isLoading"
      :disabled="$root.$isDisabled"
      @success="success"
      @cancel="closeCreateDialog"
    )

  v-dialog(v-model="importDialog" max-width="850px")
    Import(
      v-if="importDialog"
      @success="successImport"
      @cancel="importDialog = false"
    )
</template>

<script>
import { mapActions } from 'vuex';

export default {
  components: {
    Form: () => import('@/views/employees/components/Form.vue'),
    Import: () => import('@/views/employees/import.vue'),
  },
  data() {
    return {
      createDialog: false,
      importDialog: false,
    };
  },
  computed: {},
  methods: {
    ...mapActions('EMPLOYEE', ['resetItem']),
    ...mapActions('EMPLOYEE_LIST', ['fetchList']),
    openEmployeeModal(action) {
      action === 'create' && this.resetItem();
      this.createDialog = true;
    },
    openImportDialog() {
      this.importDialog = true;
    },

    successImport() {
      this.fetchList();
    },

    async success(data, action) {
      this.fetchList();
      // if employee just was created we redirect on his page
      action === 'create' &&
        this.$router.push({
          name: 'employees:card',
          params: { id: data.id },
        });

      this.closeCreateDialog();

      this.$notify({
        group: 'note',
        type: 'info',
        title: 'Работник сохранен',
        text: '',
      });
    },

    closeCreateDialog(action) {
      this.createDialog = false;
    },
  },
};
</script>
